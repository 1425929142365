<template>
  <div>
    <slot>
      <div
        v-for="item in items"
        :key="item.title"
        :data-test="`${item.dataTest}_section`">
        <GroupItems
          :is-show-action-icon="isShowActionIcon"
          :item="item"
          v-on="$listeners" />
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'CollectionsSearchGroups',
  components: {
    GroupItems: () => import('./CollectionsSearchGroupItems'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isShowActionIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

